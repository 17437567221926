import { copyObject, getSelectedType } from '../commonFunction';
import { GetVideoCover } from '../webGL/commonFunc'; 
import { contextDefault } from '../timeline/timeline.config';
import {createThumbnail, uploadThumbnail} from '../../../axios/ApiProvider';

export const checkZoomValue = async (v) => {
  let value = Math.floor(v);
  if (value > 2) return false;
  if (value < -10) return false;
  return value;
}

export const fullVideoSize = async (ProjectData, selectedID) => {
  if (!selectedID) window.toastr.error('could you please select Video or Text Item?');
  if (!selectedID) return false;

  let type = getSelectedType(selectedID, ProjectData)
  const newJSONdata = Object.assign({}, ProjectData)

  return [newJSONdata, type];
}

export const removeElement = async (ProjectData, selectedID) => {
  if (!selectedID) window.toastr.error('could you please select Video or Text Item?');
  if (!selectedID) return false;
  
  const newJSONdata = Object.assign({}, ProjectData)
  const assetOb = newJSONdata.asset;

  let type = getSelectedType(selectedID, ProjectData)
  if (!type || !newJSONdata[type]) return false;
  
  const deleteItem = copyObject(newJSONdata[type][selectedID])
  if(type == 'videos'){
    if(deleteItem.transition.start.preset != '-'){
      newJSONdata[type][deleteItem.transition.start.associated_texture].transition.end = {
        associated_texture: "-",
        associated_texture_type: "-",
        duration: 0,
        preset: "-"
      }
    }
    if(deleteItem.transition.end.preset != '-'){
      newJSONdata[type][deleteItem.transition.end.associated_texture].transition.start = {
        associated_texture: "-",
        associated_texture_type: "-",
        duration: 0,
        preset: "-"
      }
    }
  }
  delete newJSONdata[type][selectedID];
  delete assetOb[selectedID];

  return [newJSONdata, deleteItem];
}

export const splitElementCreate = async (ProjectData, selectedID, newUUID, Time_Count) => {
  if (!selectedID) window.toastr.error('could you please select Video or Text Item?');
  if (!selectedID) return [false, '', ''];

  let type = getSelectedType(selectedID, ProjectData)
  const newJSONdata = Object.assign({}, ProjectData)
  const currentTime = Time_Count.getTime() + 1;

  if (!type || !newJSONdata[type]) return [false, '', ''];

  const selectedItem = Object.assign({}, newJSONdata[type][selectedID]);
  if (currentTime < selectedItem.timeline_start || currentTime > selectedItem.timeline_end || !currentTime) return [false, '', ''];

  let newELitem = selectedItem;
  newELitem.timeline_start = currentTime;
  newELitem.duration = selectedItem.timeline_end - currentTime;
  newELitem.groups = selectedID;
  //newJSONdata[type][selectedID].timeline_end = currentTime + 1;
  newJSONdata[type][selectedID].timeline_end = currentTime;
  newJSONdata[type][selectedID].duration = currentTime - newJSONdata[type][selectedID].timeline_start;

  const elements = await getElementSetup(newJSONdata[type], newELitem, selectedID, newUUID);
  newJSONdata[type] = elements;

  if (type === 'videos' || type === 'audios') {
    newJSONdata[type][newUUID].segment_start = newJSONdata[type][newUUID].segment_end - newJSONdata[type][newUUID].duration;
    newJSONdata[type][selectedID].segment_end = newJSONdata[type][selectedID].segment_start + newJSONdata[type][selectedID].duration;
  }

  return [newJSONdata, type];
}

export const duplicateElementCreate = async (ProjectData, selectedID, newUUID) => {
  if (!selectedID) window.toastr.error('could you please select Video or Text Item?');
  if (!selectedID) return [false, '', ''];

  let type = getSelectedType(selectedID, ProjectData)
  const newJSONdata = Object.assign({}, ProjectData)
  if (!type || !newJSONdata[type]) return [false, '', ''];

  const selectedItem = Object.assign({}, newJSONdata[type][selectedID]);
  let newELitem = selectedItem;
  // newELitem.groups = selectedID;
  newELitem.groups = newUUID;
  newELitem.timeline_start = selectedItem.timeline_end;
  newELitem.timeline_end = selectedItem.duration + selectedItem.timeline_start;
  
  const elements = await getElementSetup(newJSONdata[type], newELitem, selectedID, newUUID);
  newJSONdata[type] = elements;

  return [newJSONdata, type];
}

export const freezeFrameCreate = async (ProjectData, selectedID, VideoElement, Time_Count) => {
  console.log(ProjectData, selectedID, VideoElement, Time_Count, Time_Count.valueOf());
  const videoData = ProjectData.videos[selectedID];
  const assetId = ProjectData.videos[selectedID].asset_uuid;
  console.log(ProjectData.videos[selectedID].asset_uuid, ProjectData.asset[assetId].thumbnail);
  
  const timeCapture = (parseFloat(Time_Count.valueOf()) - parseFloat(videoData.timeline_start)) / 1000;
  const timestamps = [timeCapture];
  const blobImg = await GetVideoCover(VideoElement, timeCapture);
  // console.log(timestamps, parseFloat(Time_Count.valueOf()) - parseFloat(videoData.timeline_start));
  // const thumbnailPackResp = await createThumbnail(assetId, timestamps, timestamps.length,);
  // console.log(thumbnailPackResp);
  // const thumbs = Object.values(thumbnailPackResp.data);
  // const uploadStatus = await uploadThumbnail(thumbs[0].signedurl, blobImg);
  // console.log(uploadStatus);
  // const blobImg = await GetVideoCover(VideoElement, Time_Count);
  // console.log(blobImg);
  return blobImg;
}

const getElementSetup = (Elements, newELitem, selectedID, newUUID) => {
  const ELKeys = Object.keys(Elements);
  if (!Elements[selectedID]) return false;
  let additionELItem = JSON.parse(JSON.stringify(newELitem));
  let groupCount = 0;
  const selectedGroup = Elements[selectedID].groups;
  ELKeys.forEach((key) => { if (Elements[key].groups === selectedGroup) groupCount += 1; });

  let goneCount = 0;
  const newElements = {};
  ELKeys.forEach((key) => {
    newElements[key] = Elements[key];
    if (Elements[key].groups === selectedGroup) goneCount += 1;
    if (goneCount === groupCount) {
      newElements[newUUID] = additionELItem;
      let transitionData = {
        associated_texture: "-",
        associated_texture_type: "-",
        duration: 0,
        preset: "-"
      }
      newElements[newUUID].transition = {
        start: transitionData,
        end: transitionData
      }
      /*newElements[newUUID].transition.start = transitionData;
      newElements[newUUID].transition.end = transitionData;*/
    }
  })
  
  return newElements;
}

export const checkVideoEL = async (ProjectData, selectedID) => {
  const videoOb = Object.assign({}, ProjectData.videos);
  if (!videoOb || !selectedID) return false;

  const selectedItem = videoOb[selectedID];
  if (!selectedItem) return false;
  return selectedItem;
}

export const checkVolumeValue = (v) => {
  v = Number(v);
  if (v < 0 || (!v && v !== 0)) v = 0;
  if (v > 1) v = 1;
  return v
}

export const nextTimeCalc = async (Time_Count, type) => {
  if (type !== 'next' && type !== 'before') return false;
  let timeCount = Time_Count.getTime();
  const maxTime = contextDefault.maxTime * 1000;
  // const speedTime = contextDefault.playSpeedTime;
  const speedTime = 1000;

  if (timeCount === 0 && type === 'before') return false;
  if (timeCount === maxTime && type === 'next') return false;

  if (type === 'next') timeCount += speedTime;
  if (type === 'before') timeCount -= speedTime;

  if (timeCount < 0) timeCount = 0;
  if (timeCount > maxTime) timeCount = maxTime;

  return new Date(timeCount);
}
import { createContext } from "react";

export const TimeLineContext = createContext();
export const contextDefault = {
  timeline: null,
  elements: null,
  indicator: null,
  playSpeedTime: 10,
  maxTime: 7200,
  legendInfo: { zoom: 0, diffCount: 5, sRuller: 5, sChild: 1 }
};

export const timelineConfig = {
  startLeft: 10,
  Field: 40,
  lineW: 2,
};

export const LineWOb = { width: `${timelineConfig.lineW}px` };
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Grid, Box, Divider } from '@mui/material'
import { v4 as uuidv4 } from 'uuid';
import './video.Edit.scss';
import onScrollIcon from "../../assets/image/icons/onScroll.png"
import offScrollIcon from "../../assets/image/icons/offScroll.png"
import { VideoTimeline } from '../../component/video-edit/timeline'
import { MenuBar } from '../../component/video-edit/menuBar/menuBar'
import { HeaderSection } from '../../component/video-edit/header/edit.header'
import { VideoDisplay } from '../../component/video-edit/display/videoDisplay'
import { VideoController } from '../../component/video-edit/controller/controller'
import { MobileControl } from '../../component/video-edit/controller/mobile.controller'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
// import { SubtitleEdit } from '../../component/video-edit/subtitleEdit/subtitleEdit'
import { TooltapText } from '../../component/video-edit/tooltap/tooltap.text'
import { TooltapUpload } from '../../component/video-edit/tooltap/tooltap.upload'
import { TooltapSettings } from '../../component/video-edit/tooltap/tooltap.setting'
import { TooltapSubtitles } from '../../component/video-edit/tooltap/tooltap.subtitles'
import { TooltapTransitions } from '../../component/video-edit/tooltap/tooltap.transitions'
import { TooltapTranslations } from '../../component/video-edit/tooltap/tooltap.translations'
import { TooltapElements } from '../../component/video-edit/tooltap/tooltap.elements'
import { TooltapFilters } from '../../component/video-edit/tooltap/tooltap.filters'
import {TooltapStock} from "../../component/video-edit/tooltap/tooltap.stock";
import { TooltapTemplate } from '../../component/video-edit/tooltap/tooltap.template';
import { TooltapTextToSpeech } from '../../component/video-edit/tooltap/tooltap.texttospeech';
import { TooltapDraw } from '../../component/video-edit/tooltap/tooltap.draw'
import {TooltapAiDirector} from '../../component/video-edit/tooltap/tooltap.aidirector';
import { TooltapAiSummary } from '../../component/video-edit/tooltap/tooltap.aisummary';
import { TooltapfabricDraw } from '../../component/video-edit/tooltap/tooltap.fabricdraw';
import { UploadMediaPopup } from "./uploadMediaPopup"

import { ProjectThumbnail } from '../../component/video-edit/project-thumbnail/thumbnail';
import { MobileToolbarClose, toolbardraghandle } from '../../component/video-edit/commonFunction'
import { GetProjectUUID } from '../../assets/script/commonFunction';
import WebcamCapture from "./capture/WebcamCapture"
import ScreenCapture from "./capture/ScreenCapture"
import WebcamScreenCapture from './capture/WebcamScreenCapture'
import { fullScreenFlag_store, changedJSON_Store } from '../../store/actions/projectdata';
import { dataLoaded_Store, audioElements_Store } from '../../store/actions/webgldata';

import { getFreeSoundData, getProjectJSON, getPexelsStock, getPixabayStockVideo } from '../../axios/ApiProvider';
import { getNewAudioStockInformation } from '../../component/video-edit/webGL/commonFunc';
import { mimeTypes } from '../../component/video-edit/tooltap/tooltap.config';
import { getMaxZIndex } from '../../component/video-edit/commonFunction';
import { getVideoLoadedData } from '../../component/video-edit/webGL/commonFunc';
import { webGlSizeOptions } from '../../component/video-edit/videoEdit.config';
import { ProjectJSON_Store } from '../../store/actions/projectdata';
import { getNewPexelsStockInformation } from '../../component/video-edit/webGL/commonFunc';
import { VideoElements_Store } from '../../store/actions/webgldata';
import { changeZeroSecond } from '../../component/video-edit/commonFunction';
import { isTimelineScroll_Store } from '../../store/actions/editAction';

export const VideoEditModule = () => {
  const dispatch = useDispatch();
  const selectedTool = useSelector(store => store.editdata.selectedTool);
  const dataLoaded = useSelector(store => store.webgldata.dataLoaded);
  const selectedID = useSelector(store => store.webgldata.selectedItemID);
  const projectJson = useSelector(store => store.projectdata.projectJSON);
  const projectUUID = GetProjectUUID();
  const audioElements = useSelector(store => store.webgldata.audioElements);
  const videoElements = useSelector(store => store.webgldata.videoElements);
  const fullScreenFlag = useSelector((store) => store.projectdata.fullScreenFlag);
  const isTimelineScroll = useSelector((store) => store.editdata.isTimelineScroll);
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");


  const setTimelineScroll = (isScrollVal) => {
    dispatch(isTimelineScroll_Store(isScrollVal));
  }

  const fullscreenRef = useRef(null);
  const [popUp, setPopUp] = useState(false);
  const [popUpShown, setPopUpShown] = useState(false)
  const [stockAdded, setStockAdded] = useState(false);

  const [vBodyTopStyle, setVBodyTopStyle] = useState({
    height: 'calc(100% - 400px)',
  })

  const [startDividerMove, setStartDividerMove] = useState(false);
  

  useEffect(() => { 
    //toolbardraghandle(); 
    MobileToolbarClose();
  }, []);

  useEffect(() => {
    if (!popUpShown && dataLoaded && !!from &&
      !!projectJson.asset && Object.keys(projectJson.asset || {}).length === 0
      && !!projectJson.images && Object.keys(projectJson.images || {}).length === 0
      && !!projectJson.text && Object.keys(projectJson.text || {}).length === 0
      && !!projectJson.videos && Object.keys(projectJson.videos || {}).length === 0) {
        setPopUp(true)
        setPopUpShown(true)
      if(from === "stockContent"){
        setPopUp(false)
        setPopUpShown(false)
      }
      if (from === "stockContent" && !stockAdded) {
        console.log('loadStcoks', searchParams.get('stockType'), searchParams.get('stockId'));
       
        setStockAdded(true);
        if(searchParams.get('stockType') === "freeSound") {
          addFreeSound(searchParams.get('stockId'));
        }
        if(searchParams.get('stockType') === "pexelVideo"){
          addPexelStock(searchParams.get('stockId'));
        }
        if(searchParams.get('stockType') === "pixabayVideo"){
          addPixabayStock(searchParams.get('stockId'));
        }
      }
  
    }
  }, [dataLoaded, projectJson, from])

 
  const addFreeSound = async (stockId) => {
    const soundRes = await getFreeSoundData(stockId);
    console.log(soundRes);
    if(soundRes.state !== "success") return;

    const newUUID = uuidv4();
    let audioUrl = soundRes.data.previews['preview-hq-mp3'];
    let audio_type = 'audio/mp3'
    let duration = soundRes.data.duration;
    getNewAudioStockInformation(projectJson, audioUrl, duration, newUUID, projectUUID, (audioPlayer, audioInfo) => {
      let changedJSON = { videos: {}, audios: {}, images: {}, asset: {}, text: {} };
      let startTime = 0;
      
      const audioKeys = Object.keys(projectJson.audios);
      audioKeys.map(aItemKey => {
        if(startTime < projectJson.audios[aItemKey].timeline_end){
          startTime = projectJson.audios[aItemKey].timeline_end;
        }
      })
      audioInfo.timeline_start = startTime;
      audioInfo.timeline_end = audioInfo.duration + startTime;

      changedJSON.audios = { [newUUID]: audioInfo }
      dispatch(audioElements_Store({...audioElements, [newUUID]: audioPlayer}));
      dispatch(changedJSON_Store(changedJSON));
    }, async (newAssets, assetUUID) => {
      let changedData = { videos: {}, audios: {}, images: {}, asset: {}, text: {} };
      const JSONdata = await getProjectJSON(projectUUID);
      const projectData = JSONdata?.data[projectUUID];
      if(JSONdata.state !== 'success'){
        changedData = { ...changedData, asset: { ...newAssets } };
      } else changedData = { ...changedData, asset: projectData.asset };
      dispatch(changedJSON_Store(changedData));
    }, () => { dispatch(dataLoaded_Store(true)) })

  }

  const addPexelStock = async (stockId) => {
    const pexelRes = await getPexelsStock(stockId);
    if(pexelRes.state !== "success") return;
    const hVideo = pexelRes.data.video_files[0].link;
    const video_pictures = pexelRes.data.video_pictures;
    const duration = pexelRes.data.duration * 1000;
    // projectUUID, projectJSON, signed_url, originalFileName, mimeType, duration, thumbNails, videoLength, zIndex, callBack
    const stockUUID = uuidv4()
    // const thumbnails = {}
    const thumbnails = []

    video_pictures.map((p, index) => {
      let timestamp = (duration/1000) / (video_pictures.length + 1) * p.nr;
      const extension = p.picture.split("?")[0].split(".").reverse()[0];
      thumbnails.push({
        url: p.picture,
        timestamp,
        content_type: mimeTypes[extension]
      })
    })
      
      let startTime = 0

      let maxZIndex = getMaxZIndex(projectJson);
      let changedJSON = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }

      const newProjectJSON = {...projectJson};
      if(Object.keys(videoElements).length === 0) {
        getVideoLoadedData(hVideo, uuidv4(), {segment_start: 10}, stockUUID, (res, videoPlayer) => {
          const videoPlayerW = videoPlayer.videoWidth;
          const videoPlayerH = videoPlayer.videoHeight;
          if(videoPlayerH / videoPlayerW > 1) {
            newProjectJSON.width = webGlSizeOptions[0].wSize.width;
            newProjectJSON.height = webGlSizeOptions[0].wSize.height;
            dispatch(ProjectJSON_Store(newProjectJSON));
          }
        })
      }

      
      await getNewPexelsStockInformation(projectUUID, stockUUID, newProjectJSON, hVideo, hVideo, hVideo.split("/").reverse()[0], duration, thumbnails, Object.keys(videoElements).length, maxZIndex,
      (videoPlayer, videoInfo, newAssets, assetUUID, thumbnail_info_json) => {

        const tempThumbnails = {}
        Object.values(thumbnail_info_json).map(th => {
          tempThumbnails[th.uuid] = {
            signed_url: th.external_url,
            timestamp: th.timestamp
          }
        })

        dispatch(VideoElements_Store({ ...videoElements, [stockUUID]: videoPlayer }));
        changedJSON.flag = true;
        changedJSON.videos = {
            [stockUUID]: {
              ...videoInfo,
              timeline_start: startTime,
              timeline_end: videoInfo.duration + startTime,
              asset_uuid: assetUUID,
              asset_filename:  hVideo,
              asset_extension : hVideo.split("/").reverse()[0]
            }
        }

        const tempAssets = {
          ...newAssets,
          [assetUUID]: {
            ...newAssets[assetUUID],
            thumbnail: tempThumbnails
          }
        }

        dispatch(changedJSON_Store(changedJSON))
        dispatch(ProjectJSON_Store({
          ...newProjectJSON,
          asset: {
            ...newProjectJSON.asset,
            ...tempAssets
          }
        }))
        
        changeZeroSecond(videoInfo, dispatch)
      }); 
  }
  const addPixabayStock = async (stockId) => {
    const pixabayRes = await getPixabayStockVideo(stockId);
    if(pixabayRes.state !== "success") return;
    const hVideo = pixabayRes.data.hits[0].videos.small.url;
    const video_pictures = pixabayRes.data.hits[0].videos.small.thumbnail;
    const duration = pixabayRes.data.hits[0].duration * 1000;
    // projectUUID, projectJSON, signed_url, originalFileName, mimeType, duration, thumbNails, videoLength, zIndex, callBack
    const stockUUID = uuidv4()
    // const thumbnails = {}
    let timestamp = duration;
    const thumbnails = []
    thumbnails.push({
      url: video_pictures,
      timestamp,
      content_type:  mimeTypes['jpg']});

    // video_pictures.map((p, index) => {
    //   console.log(p);
    //   let timestamp = (duration/1000) / (video_pictures.length + 1) * p.nr;
    //   thumbnails.push({
    //     url: p.picture,
    //     timestamp,
    //     content_type: mimeTypes['jpg']
    //   })
    // })
      
      let startTime = 0

      let maxZIndex = getMaxZIndex(projectJson);
      let changedJSON = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }

      const newProjectJSON = {...projectJson};
      if(Object.keys(videoElements).length === 0) {
        getVideoLoadedData(hVideo, uuidv4(), {segment_start: 10}, stockUUID, (res, videoPlayer) => {
          const videoPlayerW = videoPlayer.videoWidth;
          const videoPlayerH = videoPlayer.videoHeight;
          if(videoPlayerH / videoPlayerW > 1) {
            newProjectJSON.width = webGlSizeOptions[0].wSize.width;
            newProjectJSON.height = webGlSizeOptions[0].wSize.height;
            dispatch(ProjectJSON_Store(newProjectJSON));
          }
        })
      }

      
      await getNewPexelsStockInformation(projectUUID, stockUUID, newProjectJSON, hVideo, hVideo, hVideo.split("/").reverse()[0], duration, thumbnails, Object.keys(videoElements).length, maxZIndex,
      (videoPlayer, videoInfo, newAssets, assetUUID, thumbnail_info_json) => {

        const tempThumbnails = {}
        Object.values(thumbnail_info_json).map(th => {
          tempThumbnails[th.uuid] = {
            signed_url: th.external_url,
            timestamp: th.timestamp
          }
        })

        dispatch(VideoElements_Store({ ...videoElements, [stockUUID]: videoPlayer }));
        changedJSON.flag = true;
        changedJSON.videos = {
            [stockUUID]: {
              ...videoInfo,
              timeline_start: startTime,
              timeline_end: videoInfo.duration + startTime,
              asset_uuid: assetUUID,
              asset_filename:  hVideo,
              asset_extension : hVideo.split("/").reverse()[0]
            }
        }

        const tempAssets = {
          ...newAssets,
          [assetUUID]: {
            ...newAssets[assetUUID],
            thumbnail: tempThumbnails
          }
        }

        dispatch(changedJSON_Store(changedJSON))
        dispatch(ProjectJSON_Store({
          ...newProjectJSON,
          asset: {
            ...newProjectJSON.asset,
            ...tempAssets
          }
        }))
        
        changeZeroSecond(videoInfo, dispatch)
      }); 
  }
  
  useEffect(() => {
    function handleFullscreenChange({ target }) {
      let fullScreenEl = (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      )

      if (target.id === "fullScreen_wrapper" && !fullScreenEl) {
        setTimeout(() => { dispatch(fullScreenFlag_store(false)) }, 100);
      }
    }

    document.addEventListener("fullscreenchange", handleFullscreenChange)
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange)
    document.addEventListener("mozfullscreenchange", handleFullscreenChange)
    document.addEventListener("MSFullscreenChange", handleFullscreenChange)

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange)
      document.removeEventListener("webkitfullscreenchange", handleFullscreenChange)
      document.removeEventListener("mozfullscreenchange", handleFullscreenChange)
      document.removeEventListener("MSFullscreenChange", handleFullscreenChange)
    }
  }, [])

  useEffect(() => {
    if (fullScreenFlag) {
      const element = fullscreenRef.current;
      if (!document.fullscreenElement) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      }
    }
  }, [fullScreenFlag])

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }

  const onDragStartDivider = (e) => {
    console.log('DividerStart', e)
    setStartDividerMove(true);
  }
  const onDragEndDivider = (e) => {
    console.log('DividerEnd', e)
    setStartDividerMove(false);
  }
  const onDragDivider = (e) => {
    if(startDividerMove){
      console.log('DividerDrag', e, window.innerHeight, (e.screenY/ window.innerHeight) * 100, (e.clientY / window.innerHeight) * 100)
      setVBodyTopStyle({
        height: `${(e.clientY / window.innerHeight) * 100}%`
        // maxHeight: 'calc(100% - 300px)'
      })
    }
    
  }
  return (
    <Box sx={{ flexGrow: 0 }} className="v-editor">
      <div className='w-full h-full flex flex-col'>
        <div className='max-h-60 h-60 v-border_b'>
          <HeaderSection />
        </div>

        <div className='flex-1 flex-flex-col'>
          <Box className='v-editor-container'>
            <Box className='v-menubar-wrapper v-border_r'>
              {!!selectedID && (
                <Box className='v-mobile-controller'>
                  <MobileControl />
                </Box>
              )}

              <Box className={`v-menubar ${!selectedID ? 'v-mobile-menubar' : ''}`}>
                <MenuBar />
              </Box>
            </Box>

            <Box className='v-body' onMouseMove={onDragDivider} onMouseUp={onDragEndDivider}>
              <Grid container className='v-body-top v-border_b' sx={vBodyTopStyle}>
                <Grid item className="v-menu-toolbar v-border_r v-scroll overflow-hidden">
                  <Box className='v-toolbarhandleheader'>
                    <Box className='v-toolbarhandleheaderitem'></Box>
                  </Box>

                  <Box className='relative flex-1'>
                    <ToolTapActive active={selectedTool} />
                  </Box>
                </Grid>

                <Grid item xs={12} className="v-display px-10 py-20">
                  <Grid item xs={12} ref={fullscreenRef} id="fullScreen_wrapper"
                    className="v-display-body v-VideoDisPlay relative bg-white"
                  >
                    <VideoDisplay />
                    {/* <ProjectThumbnail /> */}
                  </Grid>
                </Grid>
              </Grid>
              <Divider textAlign='left' sx={{cursor: 'n-resize', height:'10px !important'}} onMouseDown={onDragStartDivider}  onMouseUp={onDragEndDivider}>
              <UnfoldMoreIcon />
              </Divider> 
              <Box className='v-body-bottom'>
                <Box className='v-controlbar'>
                  <VideoController />
                </Box>
                <Box className='flex ' sx={{height: '100%'}}>
                  <div className='flex flex-col'>
                    <img src={offScrollIcon} alt="off" width={50} height={50} style={{opacity: isTimelineScroll? 1:0.5}}  onClick={() => {setTimelineScroll(false)}} />
                    <img src={onScrollIcon} alt="off" width={50} height={50} style={{opacity: !isTimelineScroll? 1:0.5}}  onClick={() => {setTimelineScroll(true)}} />
                  </div>
                  <Box className='v-timeline'>
                    <VideoTimeline />
                  </Box>
                </Box>
                
              </Box>
            </Box>
          </Box>
        </div>
      </div>

      {/* <SubtitleEdit /> */}
      <WebcamCapture />
      <ScreenCapture />
      <WebcamScreenCapture />
      <UploadMediaPopup popUp={popUp} setPopUp={setPopUp} from={from} />
      <Box className='v-mobile-drawer' onClick={MobileToolbarClose}></Box>
    </Box>
  );
}

const ToolTapActive = ({ active }) => {
  let class_name = 'absolute v-toolbar v-scroll overflow-auto'

  return (
    <>
      {active === 0 && <div className={`${class_name} flex flex-col`}>
        <TooltapSettings />
      </div>
      }

      {active === 1 && <div className={`${class_name} flex flex-col`}>
        <TooltapTemplate />
      </div>}

      {active === 2 && <div className={`${class_name} flex flex-col`}>
        <TooltapUpload />
      </div>}

      {active === 3 && 
      <div className={`${class_name} flex flex-col`}>
      <TooltapAiSummary />
    </div>
      }

      {active === 4 && <div className={`${class_name} flex flex-col`}>
        <TooltapStock />
      </div>}

      {active === 5 && <div className={`${class_name} flex flex-col`}>
        <TooltapTranslations />
      </div>}

      {/* {active === 6 && <div className={`${class_name} flex flex-col`}>
        <TooltapSubtitles />
      </div>} */}

      {active === 6 && <div className={`${class_name} flex flex-col`}>
        <TooltapTextToSpeech />
      </div>}

      {active === 7 &&  <div className={`${class_name} flex flex-col`}>
        <TooltapTransitions />
      </div>}

      {active === 8 && 
      <div className={`${class_name} flex flex-col`}>
        <TooltapAiDirector />
      </div>}

      {active === 9 && <div className={`${class_name} flex flex-col`}>
        <TooltapText />
      </div>}
      {active === 10 && <div className={`${class_name} flex flex-col`}>
        <TooltapElements />
      </div>}
      {active === 11 && <div className={`${class_name} flex flex-col`}>
        <TooltapFilters />
      </div>
      }
      {active === 12 && 
        <div className={`${class_name} flex flex-col`}>
          <TooltapfabricDraw />
        </div>
      }
    </>
  )
}